@import '../../styles/main.scss';

.notFound {
  margin: 0 20px;
  
  &__h1 {
    margin-top: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    
    text-align: center;
    
    @include onTablet {
      font-size: 30px;
    }
  }

  &__button {
    margin: 48px auto 0;
    
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    border: none;
    background-color: $color--Primary-100;

    max-width: 400px;
    height: 44px;
    padding: 12px 16px;

    color: $color--Primary-500;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;

    &:hover {
      background-color: $color--Primary-200;
    }

    @include onTablet {
      font-size: 18px;
    }
  }
}