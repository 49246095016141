@import '../../styles/main.scss';

.menu {
  height: 100vh;

  padding: 0 20px 40px;
  background: $color--Beige-100;
  // opacity: 1;
  z-index: 99 !important;

  &__list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__language {
    margin-top: 32px;
  }

  &__link {
    color: $color--Dark-1000;

    font-size: 32px;
    font-style: normal;
    line-height: 124%;
    letter-spacing: -0.64px;
  }

  &__contacts {
    &--links {
      display: flex;
      gap: 8px;
    }

    &--email {
      margin: 32px auto 24px;
      color: $color--Dark-1000;

      font-size: 18px;
      font-style: normal;
    }

    &--description {
      color: $color--Dark-800;

      line-height: 116%;
    }

    &--link {
      width: 56px;
      height: 56px;
    }

    &--facebook {
      background: url("../../img/facebook-menu.svg") no-repeat center;
    }

    &--instagram {
      background: url("../../img/instagram-menu.svg") no-repeat center;
    }

    &--tiktok {
      background: url("../../img/tiktok-menu.svg") no-repeat center;
    }
  }
}