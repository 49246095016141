@import '../../styles/main.scss';

.language {
  color: $color--Dark-1000;
  font-size: 18px;
  line-height: 124%;
  
  &__button {
    margin-right: 8px;
    padding: 10px;

    width: 44px;
    height: 44px;

    border: none;
    cursor: pointer;
    background: none;

    &:hover {
      border-radius: 100px;
      background: $color--Beige-200;
    }
  }

  &__active {
    border-radius: 100px;
    background: $color--Beige-300;
  }

  &:active {
    border-radius: 100px;
    background: $color--Beige-300;
  }

  &:disabled {
    color: red;
  }
}