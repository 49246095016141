@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');

// reset css

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  font-family: 'Manrope', sans-serif;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ul,
li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

#root,
#__next {
  isolation: isolate;
}

a {
  color: inherit;
  text-decoration: inherit;
}

html {
  scroll-behavior: smooth;
}

* {
  transition: all 0.5s ease;
}

p,
h1,
h2,
h3,
h4,
h5,
div {
  font-size: 14px;
  font-weight: 500;
}