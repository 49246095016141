@import '../../styles/main.scss';

.people {
  padding: 64px 20px 0;
  background: $color--Beige-100;

  @include onDesktop {
    padding: 160px 78px 0;
  }

  @include onMaxDesktop {
    padding: 160px 238px 0;
  }

  &__img {
    display: flex;
    margin: 0 auto;
    min-width: 280px;
  }

  &__description {
    padding-top: 64px;
    margin: 0 auto;
    max-width: 506px;

    color: $color--Dark-1000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    line-height: 124%;
    letter-spacing: -0.56px;

    @include onDesktop {
      font-size: 64px;
      line-height: 67px;
      letter-spacing: -0.02em;
      text-align: center;
    }

    &--wrapper {
      display: flex;

      font-size: 28px;
      align-items: center;

      @include onTablet {
        justify-content: center;
      }

      @include onDesktop {
        font-size: 64px;
        justify-content: start;
      }
    }

    &--this {
      font-size: 28px;

      @include onDesktop {
        font-size: 64px;
      }
    }

    &--people {
      color: $color--Dark-1000;
      text-align: center;
      font-family: 'Caveat';
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -1.04px;

      @include onDesktop {
        font-size: 92px;
        line-height: 74px;
        letter-spacing: -0.02em;
      }
    }

    &--life {
      text-align: start;
      font-size: 28px;
      font-style: normal;

      line-height: 124%;
      letter-spacing: -0.56px;

      @include onTablet {
        text-align: center;
      }

      @include onDesktop {
        margin-top: -8px;
        font-size: 64px;
        line-height: 67px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }

    &--title {
      margin-top: 24px;

      color: $color--Beige-text;
      text-align: start;
      font-size: 16px;
      font-style: normal;
      line-height: 124%;

      @include onTablet {
        text-align: center;
      }

      @include onDesktop {
        margin-top: 40px;

        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }
  }

  &__wrapper {
    position: relative;

    @include onDesktop {
      margin-top: 160px;
      padding-bottom: 160px;
    }
  }

  &__container {
    padding-top: 48px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include onDesktop {
      padding-bottom: 678px;
    }

    &--card {
      width: calc(50% - 0.5em);
      margin-bottom: 2em;

      @include onDesktop {
        margin: 0;
        position: absolute;
        width: 190px;
      }

      @include onMaxDesktop {
        width: 224px;
      }
    }

    &--photo {
      margin: 0 auto;

      @include onDesktop {
        margin: 0;
        width: 102px;
        height: 102px;
      }
    }

    &--text {
      margin-top: 16px;

      color: $color--Dark-1000;
      font-style: normal;
      line-height: 116%;

      @include onTablet {
        text-align: center;
      }

      @include onDesktop {
        font-size: 16px;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}