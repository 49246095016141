$color--Primary-1000: #0E265D;
$color--Primary-900: #0D3D9B;
$color--Primary-800: #0C4CC7;
$color--Primary-700: #0057FF;
$color--Primary-600: #066CFF;
$color--Primary-500: #1E8DFF;
$color--Primary-400: #48B1FF;
$color--Primary-300: #83CEFF;
$color--Primary-200: #B5E0FF;
$color--Primary-100: #D6ECFF;
$color--Primary-50: #EDF7FF;

$color--Dark-1000: #212833;
$color--Dark-800: #354457;
$color--Dark-600: #4A627F;
$color--Dark-400: #7E97B2;

$color--Beige-text: #57524C;
$color--Beige-800: #6E483E;
$color--Beige-600: #A26C54;
$color--Beige-400: #BD967A;
$color--Beige-300: #E5D9CB;
$color--Beige-200: #E5D9CB;
$color--Beige-100: #F3EDE6;
$color--Beige-50: #F9F6F3;
$color--Beige-0: #fff;


$color--Yellow-600: #FFD600;
$color--Red-600: #D00B0B;
