@import '../../styles/main.scss';

.privacy {
  padding: 48px 20px 64px;
  display: flex;
  justify-content: center;

  background: $color--Beige-100;

  @include onDesktop {
    padding: 64px 78px 120px;
  }

  @include onMaxDesktop {
    padding: 64px 238px 120px;
  }

  &__message {
    width: 280px;
  }

  &__wrapper {
    margin: 0 auto;

    @include onDesktop {
      margin: 0;

      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__button {
    margin: 0 auto;
    border-radius: 100px;
    border: none;
    background-color: $color--Primary-100;

    max-width: 320px;
    height: 44px;
    padding: 12px 16px;

    color: $color--Primary-500;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;

    &:hover {
      background-color: $color--Primary-200;
    }

    @include onDesktop {
      margin: 0;
    }
  }

  &__content {
    margin-top: 48px;

    @include onDesktop {
      margin-top: 0;
      width: 612px;
    }
  }

  &__headline {
    color: $color--Dark-1000;
    font-size: 32px;
    font-style: normal;
    line-height: 124%;
    letter-spacing: -0.64px;

    @include onDesktop {
      font-size: 64px;
      line-height: 67px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }

  &__list {
    margin-top: 24px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    &--text {
      color: $color--Dark-1000;
      font-size: 16px;
      font-style: normal;
      line-height: 124%;

      @include onDesktop {
        font-size: 18px;
        line-height: 22px;
        text-align: left;
      }
    }

    &--link {
      color: $color--Primary-900;

    }

    &--item {
      position: relative;
      gap: 16px;
      padding-left: 16px;

      @include onDesktop {
        font-size: 18px;
        line-height: 22px;
        text-align: left;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background-color: $color--Primary-700;
        border-radius: 50%;
      }
    }
  }

  &__permits {
    margin-top: 48px;

    &--title {
      color: $color--Dark-1000;
      font-size: 24px;
      font-style: normal;
      line-height: 124%;
      letter-spacing: -0.48px;

      @include onDesktop {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }

    &--description {
      margin-top: 24px;

      color: $color--Dark-1000;
      font-size: 16px;
      font-style: normal;
      line-height: 124%;

      @include onDesktop {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }

}