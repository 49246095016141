@import '../../styles/main.scss';

.faq {
  padding: 64px 20px;
  background: $color--Beige-100;

  @include onTablet {
    padding: 100px auto 80px;
  }

  @include onDesktop {
    padding: 160px 0 120px;
  }

  &__answer {
    color: $color--Dark-1000;
    font-size: 28px;
    text-align: center;
    line-height: 124%;

    @include onTablet {
      font-size: 64px;
      line-height: 67px;
      letter-spacing: -0.02em;
    }
  }

  &__question {
    margin: -4px auto 0;
    color: $color--Dark-1000;
    text-align: center;
    font-family: 'Caveat';
    font-size: 52px;
    font-weight: 400;
    line-height: 100%;

    @include onTablet {
      font-size: 92px;
      line-height: 74px;
      letter-spacing: -0.02em;
    }
  }

  &__list {
    padding-top: 48px;

    display: flex;
    flex-direction: column;
    margin: 0 auto;

    max-width: 612px;

    @include onTablet {
      padding-top: 80px;
    }
  }

  &__button {
    height: 36px;
    width: 36px;
    border: none;
    border-radius: 100px;
    cursor: pointer;

    @include onTablet {
      height: 48px;
      width: 48px;
    }

    &--default {
      background-color: $color--Beige-300;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../../img/faq-arrow-default.svg");
    }

    &--active {
      background-color: $color--Primary-700;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../../img/faq-arrow-active.svg");
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__element {
    padding: 12px 16px;
    margin-bottom: 8px;

    &--isShow {
      border-radius: 16px;
      background: $color--Beige-200;
    }

    &--title {
      color: $color--Dark-1000;

      font-size: 24px;
      font-style: normal;
      line-height: 124%;
      letter-spacing: -0.48px;

      @include onTablet {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }

    &--description {
      display: flex;
      padding: 8px 0 24px;
      align-items: flex-start;

      color: $color--Beige-text;
      font-feature-settings: 'calt' off;
      font-size: 16px;
      line-height: 124%;

      @include onTablet {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }
}