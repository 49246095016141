@import '../../styles/main.scss';

.head {
  padding: 64px 20px 0;
  font-style: normal;

  background-color: $color--Beige-100;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url('../../img/header-vector.svg');

  @include onTablet {
    background-size: cover;
    background-image: url('../../img/header-vector-desktop.svg');
  }

  @include onDesktop {
    padding: 70px 78px 0;
  }

  @include onMaxDesktop {
    padding: 120px 238px 0;
  }

  &__wrapper {
    position: relative;
  }

  &__heading {
    &--man {
      margin: 0 auto;

      width: 152px;
      height: 152px;
    }

    &--change {
      color: $color--Dark-1000;
      text-align: center;
      font-size: 32px;
      line-height: 124%;
      letter-spacing: -0.64px;

      @include onDesktop {
        font-size: 80px;
      }
    }

    &--people {
      margin-top: -1rem;

      color: $color--Dark-1000;
      text-align: center;
      font-family: 'Caveat';
      font-size: 52px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -1.04px;

      @include onDesktop {
        margin-top: -2rem;

        font-size: 116px;
        font-weight: 400;
        line-height: 116px;
        letter-spacing: -2%;
      }
    }

    &--all {
      margin-top: 0.5rem;

      color: $color--Beige-text;
      text-align: center;
      line-height: 124%;

      @include onDesktop {
        margin-top: 1rem;

        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: center;
      }
    }

    &--buttons {
      @include onTablet {
        display: none;
      }
    }
  }

  &__partners {
    margin-top: 120px;

    &--partner {
      color: $color--Beige-600;
      text-align: center;
      font-feature-settings: 'calt' off;
      font-style: normal;

      @include onDesktop {
        margin-top: 92px;

        font-size: 18px;
      }
    }

    &--companies {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      margin-top: 8px;
      gap: 24px;

      @include onDesktop {
        margin-top: 16px;
        justify-content: center;
      }
    }

    &--baza {
      width: 64px;
      height: 48px;

      @include onDesktop {
        width: 80px;
        height: 60px;
      }
    }

    &--mate {
      width: 92px;
      height: 32px;

      @include onDesktop {
        width: 116px;
        height: 40px;
      }
    }
  }

  &__contacts {
    position: absolute;

    display: none;
    flex-direction: column;
    gap: 16px;
    bottom: 0;
    left: 0;

    @include onTablet {
      display: flex;
    }

    &--link {
      width: 56px;
      height: 56px;

      &:hover {
        transform: scale(1.2) ;
      }
    }

    &--facebook {
      background: url("../../img/facebook-head.svg") no-repeat center;
    }

    &--instagram {
      background: url("../../img/instagram-head.svg") no-repeat center;
    }

    &--tiktok {
      background: url("../../img/tiktok-head.svg") no-repeat center;
    }
  }

  &__code {
    position: absolute;

    display: none;
    flex-direction: column;
    bottom: 0;
    right: 0;

    padding: 8px;
    width: 102px;
    height: 144px;
    border-radius: 8px;
    background-color: $color--Primary-600;

    &:hover {
      transform: scale(1.3) ;
    }

    @include onTablet {
      display: flex;
    }

    &--qr {
      filter: #fff;
    }

    &--title {
      color: $color--Beige-0;
      line-height: 16px;
      text-align: center;
    }
  }
}